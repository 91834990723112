<template>
  <div
    :class="{
      'osk-tab': true,
      'osk-tab_active': isActive,
      'osk-tab_disabled': $tabs?.disabled.value || disabled,
      'osk-tab_small': $tabs?.small.value,
    }"
  >
    <div
      class="osk-tab__title"
      :data-text="title ?? name"
      @click="handleClick"
    >
      <slot>
        {{ title ?? name }}
      </slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { tabsKey } from '~/types/components/tabs';

const props = defineProps<{
  name: string
  title?: string
  disabled?: boolean
}>();

const $tabs = inject(tabsKey);

const isActive = computed(() => props.name === $tabs?.activeTab.value);

function handleClick() {
  if ($tabs?.disabled.value || props.disabled || isActive.value) return;
  $tabs?.handleTabClick(props.name);
}
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-tab {
  &__title {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    padding: $indent-compact 0;
    margin: 0 $indent-medium;
    color: $grey-2;
    position: relative;
    font-family: $font-family-default;
    @include font-style($font-size-subheading, $line-height-subheading, $font-weight-bold);
    transition: all linear .2s;

    &:after {
      content: '';
      position: absolute;
      height: 2px;
      width: 0;
      background-color: $black;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      transition: all linear .2s;
    }

    @include media-query(lg-and-up) {
      @include font-style($font-size-heading, $line-height-heading, $font-weight-bold);
      display: flex;
      padding: $indent-medium $indent-mlarge $indent-medium 0;
      margin: 0;
      margin-bottom: 1px;

      &:after {
        left: 0;
        bottom: -2px;
        transform: translateX(0);
      }
    }
  }

  &_active {
    .osk-tab__title {
      color: $black;
      font-weight: $font-weight-bold;

      &:after {
        width: 100%;
      }
    }
  }

  &_small {
    &.osk-tab_active {
      .osk-tab__title {
        font-weight: $font-weight-bold;
      }
    }

    &.osk-tab:last-child {
      .osk-tab__title {
        margin-right: 0;
      }
    }

    .osk-tab__title {
      @include active-bold;
      @include font-style($font-size-subheading, $line-height-subheading, $font-weight-medium);
      margin: 0;
      margin-right: 15px;

      @include media-query(lg-and-up) {
        @include font-style($font-size-body, $line-height-body, $font-weight-bold);
        padding: $indent-compact $indent-medium;
        margin-right: 0;
      }
    }
  }

  &_disabled {
    pointer-events: none;

    .osk-tab__title {
      pointer-events: none;
      cursor: default;
    }
  }
}
</style>
