<template>
  <div class="osk-tabs">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { tabsKey, TName, TModel } from '~/types/components/tabs';

const props = defineProps<{
  modelValue: TModel
  disabled?: boolean
  small?: boolean
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', value: TModel): void
}>();

const activeTab = ref(props.modelValue);

const disabledComp = computed(() => !!props.disabled);
const smallComp = computed(() => !!props.small);

function handleTabClick(name: TName) {
  activeTab.value = name;
  emits('update:modelValue', activeTab.value);
}

watch(() => props.modelValue, (value) => {
  activeTab.value = value;
});

provide(tabsKey, {
  activeTab,
  handleTabClick,
  disabled: disabledComp,
  small: smallComp,
});
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-tabs {
  display: flex;
  align-items: center;

  @include media-query(lg-and-up) {
    border-bottom: 1px solid $grey-3;
  }
}
</style>
