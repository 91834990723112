import { InjectionKey, ComputedRef } from 'vue';

export interface ITabs {
    label: string,
    id: string | number
}

export enum NotificationTab {
    Notification = 'Notification',
    Comment = 'Comment',
}

export type TName = string;
export type TModel = TName;

interface TabsContext {
  activeTab: ComputedRef<TName>
  handleTabClick: (name: string) => void
  disabled: ComputedRef<boolean>
  small: ComputedRef<boolean>
}

export const tabsKey: InjectionKey<TabsContext> = Symbol('tabsContextKey');
